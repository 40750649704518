import axios, { AxiosError } from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

type ErrorDetails = {
  type?: string;
  title?: string;
  status?: number;
  detail?: string;
  exceptionDetails?: any;
}

axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  return response;
}, function (error: AxiosError<ErrorDetails>) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  if (error.response?.data?.detail) {
    error.message = `${error.response.data.title}: ${error.response.data.detail}`
  }
  return Promise.reject(error);
});
