import axios from "axios";
import { AccessRequestForm, IdNameDto, RoleDto } from "../models";

export const loadRoles = async(token: string) => {
  const response = await axios.get<RoleDto[]>('/roles/all', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
};

export const loadTenants = async(token: string) => {
  const response = await axios.get<IdNameDto[]>('/tenants/all', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
};

export const submitRequest = async(data: AccessRequestForm, token: string) => {
  await axios.post('/requests', data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}
