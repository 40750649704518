import { RoleDto } from "../../models";
import { SearchSelect } from "./SearchSelect"

export interface SelectRoleProps {
  onChange: (value: any) => void,
  roles: RoleDto[]
}

export function SelectRole(props: SelectRoleProps) {
  const options = props.roles.map(role => ({label: role.name + (role.autoApproval ? '*' : ''), value: role.id}));

  return <SearchSelect 
    placeholder="-Select-"
    options={options}
    onChange={props.onChange}
    mode="multiple"
  />
}