import { IdNameDto } from "../../models/IdNameDto"
import { SearchSelect } from "./SearchSelect"

export interface SelectTenantProps {
  onChange: (value: string) => void,
  tenants: IdNameDto[]
}

export function SelectTenant(props: SelectTenantProps) {
  const options = props.tenants.map(tenant => ({label: `${tenant.name} (${tenant.id})`, value: tenant.id}));

  return <SearchSelect options={options} onChange={props.onChange}/>
}