import { useAccount, useMsal } from '@azure/msal-react';
import { Button, Label, Spinner, makeStyles, shorthands } from '@fluentui/react-components';
import TextArea from 'antd/es/input/TextArea';
import { Dayjs } from "dayjs";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { loadRoles, loadTenants, submitRequest } from '../../apis/BackendApi';
import { getToken } from '../../auth';
import { CustomDatePicker } from '../../components/DatePicker';
import { PageLayout } from '../../components/PageLayout';
import { SelectRole, SelectTenant } from '../../components/Select';
import { AccessRequestForm, IdNameDto, RoleDto } from "../../models";

const useStyles = makeStyles({
  selectors: {
    display: 'flex',
	  flexDirection: 'row',
    gap: '10px'
  },
  inputItem: {
    display: "flex",
    flexDirection: "column"
  },
  buttonContainer: {
    ...shorthands.margin("10px", 0, "10px", 0),
    display: "flex",
    flexDirection: "row-reverse"
  },
  button: {
    backgroundColor: "#0099DA",
    ":hover": {
      backgroundColor: "#0077A0",
    },
    ":hover:active": {
      backgroundColor: "#006688",
    },
  }
});

export default function CreateRequestPage(props: {instance: any}) {
	const { accounts } = useMsal();
	const account = useAccount(accounts[0] || {});

	const navigate = useNavigate();

	const [ data, setData ] = useState({
		userName: account.name,
		userEmail: account.username,
    roleIds: []
	} as AccessRequestForm);
  const [ tenantList, setTenantList ] = useState([] as IdNameDto[]);
  const [ roleList, setRoleList ] = useState([] as RoleDto[]);
  const [ token, setToken ] = useState('');
  const [ isLoading, setLoading ] = useState(false);

  useEffect(() => {
    getToken(props.instance, account)
      .then(result => setToken(result))
      .catch(error => alert(error));
  }, [props, account]);

  useEffect(() => {
    if (!token) return;

    loadRoles(token)
      .then(result => {
        setRoleList(result)
      })
      .catch(error => {
        alert(error);
      });

		loadTenants(token)
      .then(result => {
        setTenantList(result)
      })
      .catch(error => {
        alert(error);
      });
  }, [token]);

	const onSubmit = () => {
    if (isLoading) return;
    if (!(data.tenantId && data.domain && data.endDate && data.reason && data.roleIds.length > 0)) {
      alert("Fill in all fields");
      return;
    }
    
    setLoading(true);
    submitRequest(data, token)
      .then(() => navigate(`../success`))
      .catch(error => {
        alert(error);
        setLoading(false);
      });
	}

	const onReasonChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		data.reason = event.target.value;
    setData(data);
  }

	const onTenantChange = (value: string) => {
		data.tenantId = value;
		data.domain = tenantList.find(x => x.id === value).name;
    setData(data);
	}
	
	const onRoleChange = (value: string[]) => {
		data.roleIds = value;
    setData(data);
  }
	
	const onDatesChange = (value: Dayjs) => {
		if (value) {
			data.endDate = value.format("YYYY-MM-DD");
    } else {
      data.endDate = null;
    }
    setData(data);
	}

  const styles = useStyles();
  
  return (
    <PageLayout>
      <>
        <h2>
          New access request
        </h2>
        <div className={styles.selectors}>
          <div className={styles.inputItem}>
            <Label>Tenant</Label>
            <SelectTenant onChange={onTenantChange} tenants={tenantList}/>
          </div>
          <div className={styles.inputItem}>
            <Label>Role</Label>
            <SelectRole onChange={onRoleChange} roles={roleList}/>
            <Label style={{color: '#C42B1C'}}>Roles marked with '*' will be assigned automatically</Label>
          </div>
          <div className={styles.inputItem}>
            <Label>Required until</Label>
            <CustomDatePicker
              onChange={onDatesChange}
            />
          </div>
        </div>
        <div className={styles.inputItem}>
          <Label>Reason</Label>
          <TextArea rows={6} onChange={onReasonChange} />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            className={styles.button}
            icon={isLoading ? <Spinner size="tiny" /> : null}
            onClick={onSubmit}
            size="large"
            appearance="primary">
              Submit
          </Button>
        </div>
      </>
    </PageLayout>
  );
}