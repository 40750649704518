import { useAccount, useMsal } from "@azure/msal-react";
import { Text, PopoverSurface, Popover, PopoverTrigger, Button, makeStyles, Avatar } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { getProfilePhoto } from "../apis/GraphApi";

const useStyles = makeStyles({
	popover: {
	  display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center',
    gap: '5px'
	}
});

export const UserAvatar = () => {
  const [blobUrl, setBlobUrl] = useState<string | null>(null);
	const { accounts, instance } = useMsal();
	const account = useAccount(accounts[0] || {});
  
  useEffect (() => {
    getProfilePhoto(instance, account)
      .then((data) => {
        setBlobUrl(URL.createObjectURL(data));
      })
      .catch((error) => {
        console.error(error);
      });
  }, [instance, account]);

  const styles = useStyles();
	return (
		<Popover positioning="below">
			<PopoverTrigger disableButtonEnhancement>
        <Avatar name={account?.name} size={48} image={{src: blobUrl}}/>
			</PopoverTrigger>
			<PopoverSurface className={styles.popover}>
        <Text weight="semibold">{account?.name}</Text>
				<Button onClick={() => instance.logoutRedirect({ postLogoutRedirectUri: "/" })}>Logout</Button>
			</PopoverSurface>
		</Popover>
	);
};