import { AccountInfo, Configuration, InteractionRequiredAuthError, IPublicClientApplication } from "@azure/msal-browser";

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const MsalConfig: Configuration = {
  auth: {
    clientId:  `${process.env["REACT_APP_AAD_CLIENT_ID"]}`, // This is the ONLY mandatory field that you need to supply.
    authority: `https://login.microsoftonline.com/${process.env['REACT_APP_AAD_TENANT_ID']}`,
    redirectUri: window.location.origin, // You must register this URI on Azure Portal/App Registration.
    //postLogoutRedirectUri: window.location.origin, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage"
  }
};

const requestScopes = [];

//Get token from Graph
export async function getToken(msalClient: IPublicClientApplication, account: AccountInfo): Promise<string> {
  try {
    // First, attempt to get the token silently
    const silentResult = await msalClient.acquireTokenSilent({
      scopes: requestScopes,
      account: account
    });
    return silentResult.idToken;
  } catch (silentError) {
    // If silent requests fails with InteractionRequiredAuthError,
    // attempt to get the token interactively
    if (silentError instanceof InteractionRequiredAuthError) {
      const interactiveResult = await msalClient.acquireTokenPopup({ scopes: requestScopes });
      return interactiveResult.idToken;
    } else {
      throw silentError;
    }
  }
}
