import { makeStyles } from "@fluentui/react-components";
import { PageLayout } from "../../components/PageLayout";

const useStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "350px"
  }
});

export default function SuccessPage() {
  const styles = useStyles();
  return (
    <PageLayout>
      <div className={styles.content}>
        <h2>Your request has been sent.</h2>
      </div>
    </PageLayout>
  )
}