import { Card, Text, makeStyles, shorthands } from '@fluentui/react-components';
import { UserAvatar } from './UserAvatar';

const useStyles = makeStyles({
  page: {
    backgroundColor: "#FAFAFA",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh"
  },
  pageCard: {
    width: "880px",
    minHeight: "450px",
    backgroundColor: "#F5F5F5",
    borderRadius: "10px",
    ...shorthands.padding(0)
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#0099DA",
    boxShadow: "0px 4px 4px #00000025",
    ...shorthands.padding(0, "10px")
  },
  title: {
    fontSize: "36px",
    fontWeight: "bold",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Montserrat, sans-serif",
    textShadow: "0px 4px 4px #00000025"
  },
  avatar: {
    width: "67px"
  },
  body: {
    ...shorthands.margin(0, "20px")
  }
});

export const PageLayout = (props: {children: any}) => {
  const styles = useStyles();
  
  return (
    <div className={styles.page}>
      <Card className={styles.pageCard}>
        <div className={styles.header}>
          <div>
            <img src="logo.png" height="67px" alt="logo"/>
          </div>
          <Text className={styles.title} weight="medium">
            Access Management
          </Text>
          <div className={styles.avatar}>
            <UserAvatar/>
          </div>
        </div>
        <div className={styles.body}>
          {props.children}
        </div>
      </Card>
    </div>
  );
}