import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";

export const getProfilePhoto = async (instance: IPublicClientApplication, account: AccountInfo): Promise<Blob> => {
  const tokenResponse = await instance.acquireTokenSilent({
    scopes: ["User.Read"],
    account: account
  });
  const res = await fetch("https://graph.microsoft.com/v1.0/me/photos/48x48/$value", {
    headers: {
      Authorization: `Bearer ${tokenResponse.accessToken}`,
    }
  });
  const blob = await res.blob();
  return blob;
}